import { zhCN, dateZhCN, zhTW, dateZhTW, enUS, dateEnUS } from 'naive-ui'
import dayZhCn from 'dayjs/locale/zh-cn'
import dayZhHK from 'dayjs/locale/zh-hk'
import dayEn from 'dayjs/locale/en'
/** 常量 */

/** 语言常量 */
export const languageMap = new Map([
  [
    'zh-CN',
    {
      key: 'zh-CN',
      name: '简',
      code: 'sc',
      country: 'CN',
      day: dayZhCn,
      naive: {
        locale: zhCN,
        dateLocale: dateZhCN
      }
    }
  ],
  [
    'zh-HK',
    {
      key: 'zh-HK',
      name: '繁',
      code: 'tc',
      country: 'HK',
      day: dayZhHK,
      naive: {
        locale: zhTW,
        dateLocale: dateZhTW
      }
    }
  ],
  [
    'en-US',
    {
      key: 'en-US',
      name: 'EN',
      code: 'en',
      country: 'US',
      day: dayEn,
      naive: {
        locale: enUS,
        dateLocale: dateEnUS
      }
    }
  ]
])

/* 视频配置 */
export const videoPlayerConfig = new Map([
  ['videoQuality', {
    title: 'setting.video.quality',
    type: 'radio',
    config: new Map([
      [1, { title: 'setting.video.auto', key: 'auto', alias: '', value: 1 }],
      [2, { title: 'setting.video.high', key: 'high', alias: '', value: 2 }],
      [3, { title: 'setting.video.medium', key: 'medium', alias: '', value: 3 }],
      [4, { title: 'setting.video.low', key: 'low', alias: '', value: 4 }]
    ])
  }],
  ['audioChannel', {
    title: 'setting.audio.channel',
    type: 'radio',
    config: new Map([
      [1, { title: 'setting.audio.cantonese', key: 'Cantonese', alias: 'sc', shaka: 'cantonese', value: 1, youbora: 'Cantonese' }],
      [2, { title: 'setting.audio.mandarin', key: 'Mandarin', alias: 'tc', shaka: 'mandarin', value: 2, youbora: 'Mandarin' }],
      [3, { title: 'setting.audio.english', key: 'English', alias: 'en', shaka: 'english', value: 3, youbora: 'English' }],
      [4, { title: 'setting.audio.thai', key: 'Thai', alias: 'ti', shaka: 'thai', value: 4, youbora: 'Thai' }],
      [5, { title: 'setting.audio.vietnamese', key: 'Vietnamese', alias: 'vi', shaka: 'vietnamese', value: 5, youbora: 'Vietnamese' }]
    ])
  }],
  ['subtitleLanguage', {
    title: 'setting.subtitle.language',
    type: 'radio',
    config: new Map([
      [1, { title: 'setting.subtitle.simplifiedChinese', key: 'simplifiedChinese', alias: 'sc', shaka: 'zh', value: 1, youbora: 'Bahasa Indonesia' }],
      [2, { title: 'setting.subtitle.traditionalChinese', key: 'traditionalChinese', alias: 'tc', shaka: 'zh-HANT', value: 2, youbora: 'Traditional Chinese' }],
      [3, { title: 'setting.subtitle.bahasaIndonesia', key: 'bahasaIndonesia', alias: 'id', shaka: 'id', value: 3, youbora: 'Bahasa Indonesia' }],
      [4, { title: 'setting.subtitle.english', key: 'english', alias: 'en', shaka: 'en', value: 4, youbora: 'English' }],
      [5, { title: 'setting.subtitle.vietnamese', key: 'vietnamese', alias: 'vi', shaka: 'vi', value: 5, youbora: 'Vietnamese' }],
      [6, { title: 'setting.subtitle.malaysian', key: 'malaysian', alias: 'ms', shaka: 'ms', value: 5, youbora: 'Malaysian' }],
      [0, { title: 'setting.subtitle.off', key: 'off', alias: '', value: 0, youbora: 'off' }]
    ])
  }],
  ['automaticallyPlayNextEpisode', {
    title: 'setting.automaticallyPlayNextEpisode',
    type: 'switch',
    config: new Map([
      [1, { title: 'on', key: 'on', value: 1 }],
      [2, { title: 'off', key: 'off', value: 2 }]
    ])
  }],
  // ['fontSize', {
  //   title: 'setting.fontSize',
  //   type: 'radio',
  //   config: new Map([
  //     [1, { title: 'A', key: 'small', value: 1 }],
  //     [2, { title: 'A', key: 'normal', value: 2 }],
  //     [3, { title: 'A', key: 'large', value: 3 }]
  //   ])
  // }],
])


export const adsConfig = new Map([
  ['sizes', {
    'bn': {
      'login,home,ed,recentlywatched,myfavorite,search,download,main': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '728x90',
        1554: '728x90|728x180|970x90|970x250',
      },
      'prog': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '728x90',
        1554: '728x90|970x90|728x180|970x250',
      },
      'proginfo': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '728x180|320x100|320x250|300x250|250x250',
        1554: '728x90|970x90|728x180|320x100|320x250|300x250|250x250',
      },
      'player': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '728x180|320x100|320x250|300x250|250x250',
        1554: '728x90|970x90|728x180|320x100|320x250|300x250|250x250',
      },
      'live': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '320x100|320x250|300x250|250x250',
        1554: '320x100|320x250|300x250|250x250',
      },
      'epg': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        1230: '728x90|728x180',
        1554: '728x90|728x180',
      }
    },
    'lrec': {
      'login,home,ed,recentlywatched,myfavorite,search,download,main': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      },
      'prog': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      },
      'proginfo': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      },
      'player': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      },
      'live': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      },
      'epg': {
        0: '320x50|320x100|320x150|320x250|320x375|300x250',
        800: '320x50|320x100|320x150|320x250|320x375|300x250',
        1025: '320x50|320x100|320x150|320x250|320x375|300x250',
      }
    },
    'video': {
      'login,home,ed,recentlywatched,myfavorite,search,download,main': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      },
      'prog': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      },
      'proginfo': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      },
      'player': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      },
      'live': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      },
      'epg': {
        0: '1280x720',
        800: '1280x720',
        1025: '1280x720',
      }
    },
  }]
])

